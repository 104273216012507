

import React, { useContext, useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { toast } from "react-toastify";
import { format, isSameDay, parseISO } from "date-fns";
import { useHistory, useParams } from "react-router-dom";
import { SocketContext } from "../../context/Socket/SocketContext";
import Icon from '@mui/material/Icon';
import LaunchIcon from '@mui/icons-material/Launch';
import UndoRoundedIcon from '@material-ui/icons/UndoRounded';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField } from '@mui/material';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import MicTwoToneIcon from '@mui/icons-material/MicTwoTone';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Paper from '@mui/material/Paper';

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { blue, green, grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { i18n } from "../../translate/i18n";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';

import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { Tooltip } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";

import AndroidIcon from "@material-ui/icons/Android";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ContactTag from "../ContactTag";
import TicketMessagesDialog from "../TicketMessagesDialog";

import PhonelinkRingOutlinedIcon from '@mui/icons-material/PhonelinkRingOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import MessageVariablesPicker from "../MessageVariablesPicker";
import TicketTimer from "../TicketTime/TicketTimer";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
  },

  pendingTicket: {
    cursor: "unset",
  },
  queueTag: {
    background: "#FCFCFC",
    color: "#0000",
    marginRight: 1,
    padding: 1,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    fontSize: "0.8em",
    whiteSpace: "nowrap"
  },
  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  newMessagesCount: {
    position: "absolute",
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
    top: "10px",
    left: "20px",
    borderRadius: 0,
  },
  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },
  connectionTag: {

    color: "#CCC",
    marginRight: 1,
    padding: 3,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    fontSize: "0.7em",
    whiteSpace: "nowrap"
  },
  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "5px",
  },

  lastMessageTime: {
    justifySelf: "flex-end",
    textAlign: "right",
    position: "relative",
    top: -21,
    background: '#3a3b6c',
    color: '#ffffff',
    border: '1px solid #3a3b6c',
    borderRadius: 5,
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: '0.9em',
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    paddingRight: "0%",
    marginLeft: "5px",
  },


  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
  },

  acceptButton: {
    position: "absolute",
    right: "108px",
  },


  acceptButton: {
    position: "absolute",
    left: "50%",
  },


  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  ticketInfo: {
    position: "relative",
    top: -13
  },
  secondaryContentSecond: {
    display: 'flex',
    // marginTop: 5,
    //marginLeft: "5px",
    alignItems: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "flex-start",
  },
  ticketInfo1: {
    position: "relative",
    top: 13,
    right: 0
  },
  Radiusdot: {
    "& .MuiBadge-badge": {
      borderRadius: 2,
      position: "inherit",
      height: 16,
      margin: 2,
      padding: 3
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(0%, -40%)",
    },

  }
}));
{/*PLW DESIGN INSERIDO O dentro do const handleChangeTab*/ }
const TicketListItemCustom = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [ticketUser, setTicketUser] = useState(null);
  const [ticketQueueName, setTicketQueueName] = useState(null);
  const [ticketQueueColor, setTicketQueueColor] = useState(null);
  const [tag, setTag] = useState([]);
  const [whatsAppName, setWhatsAppName] = useState(null);
  const [lastInteractionLabel, setLastInteractionLabel] = useState('');
  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { setCurrentTicket } = useContext(TicketsContext);
  const { user } = useContext(AuthContext);
  const [verpreview, setverpreview] = useState(false);
  const { profile } = user;





  useEffect(() => {
    setNovoValor("")


    if (ticket.userId && ticket.user) {
      setTicketUser(ticket.user?.name);
    }
    setTicketQueueName(ticket.queue?.name?.toUpperCase());
    setTicketQueueColor(ticket.queue?.color);

    if (ticket.whatsappId && ticket.whatsapp) {
      setWhatsAppName(ticket.whatsapp.name?.toUpperCase());
    }

    setTag(ticket?.tags);

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  {/*CÓDIGO NOVO SAUDAÇÃO*/ }
  const handleCloseTicketSim = async (id, inputValue) => {
    setTag(ticket?.tags);


    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id,
        queueId: ticket?.queue?.id,
        useIntegration: false,
        promptId: null,
        integrationId: null,
        textoNoFechamento: inputValue,
        notificaFechamento: true
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/`);
    toast.info("Fechado e notificado.");

  };

  const handleCloseTicketNao = async (id) => {
    setTag(ticket?.tags);


    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id,
        queueId: ticket?.queue?.id,
        useIntegration: false,
        promptId: null,
        integrationId: null,
        textoNoFechamento: "teste",
        notificaFechamento: false
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/`);
    toast.info("Ticket Fechado Sem notificar");

  };

  useEffect(() => {
    const renderLastInteractionLabel = () => {
      let labelColor = '';
      let labelText = '';

      if (!ticket.lastMessage) return '';

      const lastInteractionDate = parseISO(ticket.updatedAt);
      const currentDate = new Date();
      const timeDifference = currentDate - lastInteractionDate;
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutesDifference = Math.floor(timeDifference / (1000 * 60));


      if (minutesDifference >= 3 && minutesDifference <= 10) {
        labelText = `(${minutesDifference} m atrás)`;
        labelColor = 'green';
      } else if (minutesDifference >= 30 && minutesDifference < 60) {
        labelText = `(${minutesDifference} m atrás)`;
        labelColor = 'Orange';
      } else if (minutesDifference > 60 && hoursDifference < 24) {
        labelText = `(${hoursDifference} h atrás)`;
        labelColor = 'red';
      } else if (hoursDifference >= 24) {
        labelText = `(${Math.floor(hoursDifference / 24)} dias atrás)`;
        labelColor = 'red';
      }


      return { labelText, labelColor };
    };

    // Função para atualizar o estado do componente
    const updateLastInteractionLabel = () => {
      const { labelText, labelColor } = renderLastInteractionLabel();
      setLastInteractionLabel(
        <Badge
          className={classes.lastInteractionLabel}
          style={{ color: labelColor }}
        >
          {labelText}
        </Badge>
      );
      // Agendando a próxima atualização após 30 segundos
      setTimeout(updateLastInteractionLabel, 30 * 1000);
    };

    // Inicializando a primeira atualização
    updateLastInteractionLabel();

  }, [ticket]); // Executando apenas uma vez ao montar o componente

  const handleReopenTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
        queueId: ticket?.queue?.id
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleAcepptTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });

      let settingIndex;

      try {
        const { data } = await api.get("/settings/");



        settingIndex = data.filter((s) => s.key === "sendGreetingAccepted");

        console.log(settingIndex);

      } catch (err) {
        toastError(err);

      }

      if (settingIndex[0].value === "enabled" && !ticket.isGroup) {
        handleSendMessage(ticket.id);

      }

    } catch (err) {
      setLoading(false);

      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }

    // handleChangeTab(null, "tickets");
    // handleChangeTab(null, "open");
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleSendMessage = async (id) => {


    const msg = `{{ms}} *{{name}}*, meu nome é *${user?.name}* e agora vou prosseguir com seu atendimento!`;
    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: `*Mensagem Automática:*\n${msg.trim()}`,
    };
    try {
      await api.post(`/messages/${id}`, message);
    } catch (err) {
      toastError(err);

    }
  };

  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
        useIntegration: status === "closed" ? false : ticket.useIntegration,
        promptId: status === "closed" ? false : ticket.promptId,
        integrationId: status === "closed" ? false : ticket.integrationId
      });

      setLoading(false);
      if (status === "open") {
        setCurrentTicket({ ...ticket, code: "#open" });
      } else {
        setCurrentTicket({ id: null, code: null })
        history.push("/tickets");
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };
  {/*CÓDIGO NOVO SAUDAÇÃO*/ }

  const handleSelectTicket = (ticket) => {
    const code = uuidv4();
    const { id, uuid } = ticket;
    setCurrentTicket({ id, uuid, code });
  };


  const renderTicketInfo = () => {
    if (ticketUser) {

      return (
        <>
          {ticket.chatbot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )}

          {/* </span> */}
        </>
      );
    } else {
      return (
        <>
          {ticket.chatbot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )}
        </>
      );
    }
  };

  const [open, openchange] = useState(false);

  const messageInputRef = useRef();
  const functionopenpop = () => {

    openchange(true);

  }
  const closepopup = () => {
    openchange(false);
    setInputValue("")
  }


  const fecharTicket = (id) => {
    console.log(id);

  }

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleClick = () => {
    // Acesse o valor do TextField aqui
    console.log('Valor do TextField:', inputValue);
    console.log('Valor do TextField:', ticket.id);
    handleCloseTicketSim(ticket.id, inputValue)
    openchange(false);
  };

  const [segundos, setSegundos] = useState(0);
  const [ativo, setAtivo] = useState(false);

  useEffect(() => {
    let intervalo = null;
    if (ativo) {
      intervalo = setInterval(() => {
        setSegundos((segundos) => segundos + 1);
      }, 1000);
    } else if (!ativo && segundos !== 0) {
      clearInterval(intervalo);
    }
    return () => clearInterval(intervalo);
  }, [ativo, segundos]);


  // Definir a animação de piscar
  const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

  // Criar um componente estilizado para o ícone com animação de piscar
  const BlinkingIcon = styled(MicTwoToneIcon)`
  animation: ${blink} 1s infinite;
  margin-right: 5px;
`;


  const [digitando, setNovoValor] = useState("");

  const socketManager = useContext(SocketContext);


  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-appMessage`, (data) => {
      if (data.message.ticketId === ticket.id) {
        setNovoValor("")

      } else {

      }

    })

    socket.on(`digitando`, (data) => {

      if (data.ticket === ticket.id) {
        setNovoValor("Esta Digitando...")

      } else {

      }

    })

    socket.on(`gravandoAudio`, (data) => {

      if (data.ticket === ticket.id) {


        setNovoValor("Esta Gravando Audio...")
        setAtivo(true);

      } else {

      }

    })

    socket.on(`naoDigitando`, (data) => {

      if (data.ticket === ticket.id) {

        // console.log(data);
        setNovoValor("")
        setAtivo(false);
        setSegundos(0);
      } else {

      }
    })


  })
  const formatarTempo = (segundos) => {
    const minutos = Math.floor(segundos / 60);
    const segundosRestantes = segundos % 60;
    return `${String(minutos).padStart(2, '0')}:${String(segundosRestantes).padStart(2, '0')}`;
  };


  const handleClickMsgVar = (value) => {
    console.log(value)

    setInputValue((prevValue) => prevValue + value);


  }
  return (


    <React.Fragment key={ticket.id}>

      <Dialog open={open} onClose={closepopup} fullWidth>

        <DialogTitle>Encerrar atendimento</DialogTitle>
        <Divider></Divider>
        <DialogContent>
          <DialogContentText sx={{ color: '#000' }}>Caso queira pode enviar uma msg de fechamento.!</DialogContentText> <br></br>
          <DialogContentText color={"error"}>Se deixar em branco ele pega o Padrão da conexão, Caso Tenha!</DialogContentText> <br></br>

          <TextField sx={{ marginBottom: "50px" }} rows={5}
            value={inputValue}
            onChange={handleInputChange}
            fullWidth
            multiline
            label="Digite sua Mensagem"
            variant="outlined" size="lg">


          </TextField>  <br></br>

          <MessageVariablesPicker

            onClick={value => handleClickMsgVar(value)}
          />





        </DialogContent>
        <DialogActions>
          <Button
            onClick={closepopup}
            color="inherit" variant="contained"> Cancelar
          </Button>
          <Button
            onClick={() => handleCloseTicketNao(ticket.id)}

            color="error" variant="contained"> Não enviar mensagem!
          </Button>
          <Button
            //onClick={() => handleCloseTicketSim(ticket.id)}
            onClick={handleClick}

            color="error" variant="contained"> Enviar Mensagem!
          </Button>



        </DialogActions>

      </Dialog>

      <TicketMessagesDialog
        open={openTicketMessageDialog}

        handleClose={() => setOpenTicketMessageDialog(false)}
        ticketId={ticket.id}
      ></TicketMessagesDialog>

      <ListItem dense button
        onClick={(e) => {
          //trava abrir chamado na tela
          // if (ticket.status === "pending" ) return;
          handleSelectTicket(ticket);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >

<        Tooltip arrow placement="right" title={ticket.queue?.name?.toUpperCase() || "SEM FILA"} >
          <span style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }} className={classes.ticketQueueColor}></span>
        </Tooltip>
        <ListItemAvatar>
          {ticket.status !== "pending" ?
            <Avatar
              variant="circular"
              style={{

                marginLeft: "-3px",
                width: "55px",
                height: "55px",
                borderRadius: "50%",
              }}
              src={ticket?.contact?.profilePicUrl}
            />
            :
            <Avatar
              style={{
                marginLeft: "-3px",
                width: "55px",
                height: "55px",
                borderRadius: "50%",
              }}
              src={ticket?.contact?.profilePicUrl}
            />
          }
        </ListItemAvatar>

        
        <ListItemText
          disableTypography

          primary={
            <span className={classes.contactNameWrapper}>
              <Typography
                noWrap
                component='span'
                variant='body2'
                color='textPrimary'
              >
                <WhatsAppIcon fontSize="small" htmlColor="green" />

                <strong>{ticket.isGroup !== false && (<strong>(GRUPO) </strong>)}</strong>
                <strong>{ticket.contact.name} {lastInteractionLabel} </strong>
                {/* Mostrar Digitando e gravação de audio.*/}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p style={{ color: "green" }}>{digitando}</p>
                  {segundos > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center', color: "red" }}>
                      <BlinkingIcon />
                      <p style={{ color: "red" }}>{formatarTempo(segundos)}</p>
                    </div>
                  )}
                </div>
                <ListItemSecondaryAction>
                  <Box className={classes.ticketInfo1}>{renderTicketInfo()}</Box>
                </ListItemSecondaryAction>

              </Typography>
            </span>

          }
          secondary={
            <span className={classes.contactNameWrapper}>

              <Typography
                className={classes.contactLastMessage}
                noWrap
                component="span"
                variant="body2"
                color="textSecondary"
              > {ticket.lastMessage && ticket.lastMessage.includes('data:image/png;base64') ?
                <MarkdownWrapper> Localização</MarkdownWrapper> :
                <MarkdownWrapper>


                  {  /* {ticket.lastMessage}*/}

                </MarkdownWrapper>
                }

                {ticket.lastMessage && verpreview ? (
                  <>
                    {ticket.lastMessage.includes('VCARD') ? (
                      <MarkdownWrapper>Novo Contato recebido</MarkdownWrapper>
                    ) : ticket.lastMessage.includes('data:image') ? (
                      <MarkdownWrapper>Localização recebida</MarkdownWrapper>
                    ) : (
                      <MarkdownWrapper>
                        {ticket.lastMessage.slice(0, 10) + '1......'}
                      </MarkdownWrapper>
                    )}
                  </>
                ) : (
                  //  <MarkdownWrapper>--- </MarkdownWrapper> 
                  <></>
                )}

                <span className={classes.secondaryContentSecond} >
                  {ticket?.whatsapp?.name ? <Badge style={{ alignItems: "center" }} className={classes.connectionTag}>
                    <PhonelinkRingOutlinedIcon fontSize="small" />  {ticket?.whatsapp?.name?.toUpperCase()}
                  </Badge> : <br></br>}
                  {ticketUser ? <Badge style={{ alignItems: "center" }} className={classes.connectionTag}>
                    <AccountCircleIcon fontSize="small" /> {ticketUser}
                  </Badge> : <br></br>}

                  <Badge style={{ alignItems: "center", paddingLeft: "10px" }} className={classes.connectionTag}>
                    <Groups2OutlinedIcon fontSize="small" />{ticket.queue?.name?.toUpperCase() || "SEM FILA"}</Badge>
                </span>
                <span style={{ paddingTop: "2px" }} className={classes.secondaryContentSecond} >
                  {tag?.map((tag) => {
                    return (
                      <ContactTag tag={tag} key={`ticket-contact-tag-${ticket.id}-${tag.id}`} />
                    );
                  })}
                </span>
              </Typography>

              <Badge
                className={classes.newMessagesCount}
                badgeContent={ticket.unreadMessages}
                classes={{
                  badge: classes.badgeStyle,
                }}
              />
            </span>
          }

        />
        <ListItemSecondaryAction>
          {ticket.lastMessage && (
            <>

              <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
                  {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                    <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                  ) : (
                    <> {format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                  )}
                </Typography>

      


              </Box>

            </>
          )}

        </ListItemSecondaryAction>

         {/*Stilo do lado esquerdo do card sinaliza a cor da fila*/}
       
     
      </ListItem>


      <Divider variant="fullWidth" component="li" />
    </React.Fragment>
  );
};

export default TicketListItemCustom;