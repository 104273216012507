import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import MenuIcon from '@mui/icons-material/Menu';



import "./modal.css"

import SendIcon from '@mui/icons-material/Send';
import api from '../../../services/api';
import toastError from '../../../errors/toastError';
import { AuthContext } from '../../../context/Auth/AuthContext';

import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));



function ModalMsgInput({ titulo, mensagem, signMessage1 }) {




  const classes = useStyles();

  const { user } = React.useContext(AuthContext);

  const handleSendMessage = async (message1) => {
    
  

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: signMessage1
      ? `*${user?.name}:*\n ${message1}`
      : `${message1}`,

    };
    try {
      await api.post(`/messages/${mensagem}`, message);
    } catch (err) {
      toastError(err);
    }
    handleClose();
    
  };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const  msgList = [
        {
          msg: "Olá em que posso ajudar?"
        },
        {
          msg: "Hey, 👋. Conte tudo e não me esconda nada, vou ajudar você no que precisar!"
        },
        {
          msg: "Já Estamos verificando!!"
        },
        {
          msg: "Só um momento por favor!!"
        },
        {
          msg: "Te ajudo em algo mais?"
        },
        {
          msg: "Vou verificar e te retorno!"
        },
        {
          msg: "Qualquer coisa estamos a disposição!!"
        },
        {
          msg: "Você pode testar por favor?"
        },
        {
          msg: "Por falta de interação, o sistema encerrou o seu atendimento automaticamente. Caso precise de mais assistência, sinta-se à vontade para abrir um novo pedido.-*"
        },
    ]
  


  return (
    <>
       
      <IconButton
          aria-label="toggle-menu"
          component="span"
          onClick={handleClickOpen}
         
        >
        <MenuIcon  />
        </IconButton>

      <Dialog
        fullScreen
        
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Repostas Rapidas
            </Typography>
           
          </Toolbar>
        </AppBar>
        <List>

        {msgList.map((objMsg, index) => (
       

          <Button 
          key={index}
          onClick={() => handleSendMessage(objMsg.msg)}
         
        variant="outlined"
        color="primary"
        className={classes.button}
        endIcon={<SendIcon/>}
      >
       {objMsg.msg}

      </Button>
          
        ))}

       
        </List>
      </Dialog>
    </>
  )
}

export default ModalMsgInput