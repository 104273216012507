import React, { useState, useEffect, useRef } from "react";
import { Avatar, Button, CardHeader } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import TicketTimer from "../TicketTime/TicketTimer";

const TicketInfo = ({ contact, ticket, onClick }) => {
  const timerRef = useRef(); // Criar a referência ao componente TicketTimer

  const { user } = ticket;
  const [userName, setUserName] = useState('');
  const [contactName, setContactName] = useState('');

  // Função para iniciar o atendimento usando a referência ao TicketTimer
  const handleIniciarAtendimento = () => {
    if (timerRef.current) {
      timerRef.current.iniciarAtendimento(); // Chamar a função iniciarAtendimento
    }
  };


   // Função para iniciar o atendimento usando a referência ao TicketTimer
   const handleResetarAtendimento = () => {
    if (timerRef.current) {
      timerRef.current.pararAtendimento(); // Chamar a função iniciarAtendimento
    }
  };


    // Função para iniciar o atendimento usando a referência ao TicketTimer
	const handlerResert= () => {
		if (timerRef.current) {
		  timerRef.current.resetarAtendimento(); // Chamar a função iniciarAtendimento
		}
	  };


  useEffect(() => {
    if (contact) {
      setContactName(contact.name);
      if (document.body.offsetWidth < 600) {
        if (contact.name.length > 10) {
          const truncadName = contact.name.substring(0, 10) + "...";
          setContactName(truncadName);
        }
      }
    }

    if (user && contact) {
      setUserName(`${i18n.t("messagesList.header.assignedTo")} ${user.name}`);

      if (document.body.offsetWidth < 600) {
        setUserName(`${user.name}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, user]);

  return (
    <>
      <CardHeader
        onClick={onClick}
        style={{ cursor: "pointer" }}
        titleTypographyProps={{ noWrap: true }}
        subheaderTypographyProps={{ noWrap: true }}
        avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
        title={`${contactName} -- Atendimento: Nº ${ticket.id}`}
        subheader={ticket.user && `${userName}`}
      />

     

	  
    </>
  );
};

export default TicketInfo;
